import React from 'react'
import PropTypes from 'prop-types'

import './tags.scss'

const Tags = ({ tags }) => (
  <div className="bl-tags">
    {tags.map(tag => <span key={tag.slug}>{tag.name}</span>)}
  </div>
);

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default Tags;

import React from "react"
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import { loadFront } from 'yaml-front-matter'

import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Header from '../components/layout/header'
import Footer from '../components/layout/footer'
// import Counters from '../components/counters'
import Tags from '../components/tags'

import './project.scss'

export const query = graphql`
query Project($slug: String) {
  allStrapiProject(filter: { slug: {eq: $slug} }) {
    edges {
      node {
        lang
        name
        slug
        info
        project_tags {
          name
          slug
        }
        Content {
          details
          header
          image {
            childImageSharp {
              fixed(height: 600) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
  storeGplayImage: file(relativePath: { eq: "store-logo-gplay.png" }) {
    childImageSharp {
      fixed(height: 50) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  storeAppstoreImage: file(relativePath: { eq: "store-logo-appstore.png" }) {
    childImageSharp {
      fixed(height: 50) {
        ...GatsbyImageSharpFixed
      }
    }
  }
}
`

const ProjectInfo = ({ info, data }) => (
  <div className="project__block__info">
    {info.site ? (<div className="info__block">
      <div className="info__block__label"><FormattedMessage id="project.info_site_label" /></div>
      <div><a href={info.site} target="_blank">{info.site}</a></div>
    </div>) : null}
    {info.year ? (<div className="info__block">
      <div className="info__block__label"><FormattedMessage id="project.info_year_label" /></div>
      <div>{info.year}</div>
    </div>) : null}
    {(info.playmarket || info.appstore) ? (<div className="info__block info__block--store-links">
      <div className="info__block__label"><FormattedMessage id="project.info_stores_label" /></div>
      {info.playmarket ? (
        <a href={info.playmarket} target="_blank">
          <Img fixed={data.storeGplayImage.childImageSharp.fixed} alt="" />
        </a>
      ) : null}
      {info.appstore ? (
        <a href={info.appstore} target="_blank">
          <Img fixed={data.storeAppstoreImage.childImageSharp.fixed} alt="" />
        </a>
      ) : null}
    </div>) : null}
  </div>
);

const Project = ({ intl, data }) => {
  const projectFind = data.allStrapiProject.edges.find((o) => o.node.lang === intl.locale);
  if (!projectFind) return null;
  const project = projectFind.node;
  if (!project) return null;
  const info = loadFront(project.info);
  return (
    <Layout showMenu={false} fullPage>
      <SEO title={project.name} />
      <Header pageHeader={intl.formatMessage({ id:"project.subheader" })} pageHeaderLink={`/${intl.locale}/projects`} />
      {/* Body */}
      <div className="page-project">
        {/* Title */}
        <div className="content">
          <h3 className="page-project__subheader"></h3>
          <h1 className="page-project__header wow fadeIn">{project.name}</h1>
        </div>
        {/* Tags */}
        <div className="page-project__tags content">
          <Tags tags={project.project_tags} />
        </div>
        {/* Content block */}
        {project.Content.map((block, i) => (
          <React.Fragment key={`project-${i}`}>
            <div className={`content project__block__container ${i === 0 ? 'project__block--with-info' : ''}`}>
              {i === 0 && <ProjectInfo info={info} data={data} />}
              <div className={`project__block`}>
                {!!block.header && (
                  <h2 className="project__block__title wow fadeIn">{block.header}</h2>
                )}
                <div className="project__block__details wow fadeIn">
                  <ReactMarkdown source={block.details} />
                </div>
              </div>
            </div>
            {block.image ? (
              <div className="project__block__image">
                <div className="content">
                  <Img fixed={block.image.childImageSharp.fixed} alt="" objectFit="contain" />
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </div>
      {/* */}
      <Footer />
    </Layout>
  );
}

Project.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
}

export default injectIntl(Project)
